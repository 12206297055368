html {
  font-size: 14px; }

::selection {
  color: #fff;
  background: #2BAECA; }

body {
  min-height: 100vh;
  font-family: 'Roboto';
  font-size: 16px;
  color: #333333;
  line-height: 1.4; }

h1 {
  padding-left: 15px;
  padding-right: 15px;
  color: #ffffff;
  font-size: 53px;
  text-transform: uppercase;
  text-align: center; }

h2 {
  margin-bottom: 0.75rem;
  font-size: 36px; }

h3 {
  margin-bottom: 0.75rem;
  font-size: 32px; }

h4 {
  margin-bottom: 0.5rem;
  font-size: 28px;
  font-weight: 400; }

h5 {
  margin-bottom: 0.5rem;
  font-size: 24px;
  font-weight: 400; }

h6 {
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 400; }

@media (max-width: 767px) {
  h1 {
    padding: 0;
    padding-top: 20px;
    font-size: 40px; }
  h2 {
    font-size: 30px; }
  h3 {
    font-size: 26px; }
  h4 {
    font-size: 20px; }
  h5 {
    font-size: 22px; }
  h6 {
    font-size: 14px; } }

@media (max-width: 576px) {
  h1 {
    font-size: 24px; } }

a {
  color: #4d90bf;
  font-size: 18px; }
  a:hover {
    color: #4169e1; }

.row {
  margin: 0; }

.table th, .table td {
  vertical-align: middle; }

nav {
  position: relative;
  z-index: 0; }
  nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  nav li {
    display: inline-block; }
  nav a {
    display: block;
    text-decoration: none; }

@media (max-width: 767px) {
  .body-overflow {
    overflow-y: hidden; } }

button:focus {
  outline: none; }

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 !important; }

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden; }

.wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  flex-grow: 1; }
  .wrapper__non-padding {
    padding: 0; }

.btn {
  padding: 0;
  border: none;
  border-radius: 0;
  vertical-align: middle;
  background-color: #4d90bf;
  color: #ffffff !important; }
  .btn:hover {
    text-decoration: none !important;
    background-color: #609cc6; }
  .btn:focus {
    box-shadow: none !important; }

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay.active {
    display: block; }

.container {
  max-width: 1200px !important; }

.title {
  width: 100%;
  text-align: center; }

.col-sm-12 {
  padding: 0; }

.form-group {
  margin: 0 !important; }

.number-spinner__group {
  display: flex;
  align-items: center; }

.row.align-items-center.wrapper {
  padding: 0; }

.form-btn {
  width: 85%;
  height: 45px;
  min-height: 45px;
  margin-left: 15%; }
  .form-btn__mobile {
    display: none;
    width: 100%;
    height: 45px;
    margin: 0; }

@media (max-width: 767px) {
  .form-btn {
    margin: 0;
    width: 100%; } }

@media (max-width: 991px) {
  .form-btn__desktop {
    display: none; }
  .form-btn__mobile {
    display: block; } }

.page-header {
  position: relative;
  z-index: 200;
  background-color: transparent; }
  .page-header__menu-opened {
    background-color: #4d5254; }

.logo-col {
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0; }
  .logo-col img {
    width: 100%;
    height: auto;
    max-width: 325px;
    max-height: 14px; }
  .logo-col .row {
    flex-wrap: nowrap; }

.logo {
  padding: 0;
  padding-top: 1px;
  line-height: 14px; }

.form-inline button {
  background-color: transparent !important;
  border: none !important; }

.form-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1; }
  .form-container form {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    .form-container form .form--section {
      display: flex;
      flex-grow: 1; }
      .form-container form .form--section input {
        flex-grow: 1;
        border: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 16px;
        border-radius: 0;
        background-color: transparent; }
        .form-container form .form--section input.filled {
          background: none;
          background-color: #ffffff;
          border: 1px solid #ffffff; }
        .form-container form .form--section input:focus {
          background-color: #ffffff;
          outline: none;
          box-shadow: none !important;
          border: 1px solid #ffffff !important; }
        .form-container form .form--section input:invalid {
          border: 1px solid #ffffff;
          background: none;
          background-color: #ffffff; }
        .form-container form .form--section input.is-invalid {
          border: 1px solid #ffffff;
          background: none;
          background-color: #ffffff; }
        .form-container form .form--section input.is-valid {
          border: 1px solid #ffffff;
          background: none;
          background-color: #ffffff; }
        .form-container form .form--section input::placeholder {
          color: rgba(255, 255, 255, 0.5); }

.status {
  margin-top: 60px;
  padding: 60px 0;
  background-color: #f7f7f7; }
  .status .status--title {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase; }
  .status .form-group {
    margin-bottom: 25px !important; }

.children-list--item {
  width: 50%;
  min-width: 300px; }
  .children-list--item a {
    font-size: 18px; }

@media (max-width: 767px) {
  .top-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 54px;
    padding: 0;
    background-color: #323232; }
  .usermenu.active {
    padding-top: 0;
    padding-bottom: 20px;
    min-height: calc(100% - 70px);
    height: calc(100% - 70px);
    overflow-y: scroll; }
    .usermenu.active .usermenu--title {
      margin-top: 35px; }
  .body-overflow {
    position: fixed;
    overflow: hidden;
    height: 100vh;
    min-height: 100vh; }
  .page-header__menu-opened {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto; }
  .search-open .lang-menu {
    display: none; }
  .search-open .top-bar--wrapper {
    width: 100%;
    padding: 0 15px; }
  .status .status--title {
    font-size: 30px; }
  .children-list--item {
    min-width: auto;
    width: 100%; } }

.children-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-left: 20px; }

.search-btn:focus {
  outline: none; }

.dialog {
  z-index: 50; }
  .dialog .modal-card-foot .is-success {
    background-color: #4d90bf; }

.navbar {
  position: relative;
  z-index: 100;
  padding: 0;
  min-height: 70px; }
  .navbar .md-button.md-theme-default {
    color: #ffffff; }

.navbar--btn {
  position: relative;
  display: block;
  width: 20px;
  height: 14px;
  margin-right: 15px;
  margin-top: 2px;
  border: none;
  padding: 0;
  background-color: transparent; }
  .navbar--btn:active {
    outline: none !important; }
  .navbar--btn:focus {
    outline: none !important; }
  .navbar--btn div {
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    transition: 0.25s ease-in-out;
    background-color: #ffffff; }
  .navbar--btn div:first-child {
    top: 0; }
  .navbar--btn div:nth-child(2) {
    top: 6px;
    width: 15px; }
  .navbar--btn div:last-child {
    top: 12px; }
  .navbar--btn.active div {
    transform-origin: left center;
    transition: 0.25s ease-in-out; }
  .navbar--btn.active div:first-child {
    transform: rotate(45deg) scaleX(0.85);
    left: 3.5px; }
  .navbar--btn.active div:nth-child(2) {
    opacity: 0;
    width: 0; }
  .navbar--btn.active div:last-child {
    transform: rotate(-45deg) scaleX(0.85);
    bottom: 0;
    left: 3.5px; }

.usermenu {
  box-sizing: border-box;
  position: absolute;
  top: 70px;
  left: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 0.2s ease-in-out; }
  .usermenu .row .col-sm {
    flex-basis: auto;
    width: auto; }
  .usermenu.active {
    z-index: 99;
    height: auto;
    padding-top: 70px;
    color: #ffffff !important;
    background-color: #323232 !important;
    transition: 0.25s ease-in-out; }
  .usermenu .usermenu--col {
    margin-bottom: 70px; }
    .usermenu .usermenu--col:not(:last-child) {
      padding-right: 15px; }
  .usermenu .usermenu--title {
    display: inline-block;
    padding-bottom: 15px;
    font-size: 18px;
    color: #ffffff !important;
    text-transform: uppercase; }
  .usermenu .usermenu--link {
    color: #ffffff !important;
    font-size: 16px; }
  .usermenu .usermenu--subtitle {
    color: #ffffff !important; }

@media (max-width: 767px) {
  .usermenu.active {
    z-index: 100;
    padding-top: 0; }
  .usermenu .usermenu--col {
    margin-bottom: 0;
    text-align: left; }
    .usermenu .usermenu--col:not(:last-child) {
      padding-right: 0; } }

.lang-menu {
  margin-left: 20px; }

.lang-menu--inner {
  position: relative; }

.lang-menu--content {
  position: absolute;
  top: 52px;
  right: 0;
  width: 122px;
  height: auto;
  outline: 1px solid #e7e7e7; }
  .lang-menu--content::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 35px;
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: #f7f7f7; }

.lang-menu--option a {
  color: #333333; }
  .lang-menu--option a:hover {
    color: #888888; }

@media (max-width: 767px) {
  .lang-menu {
    margin-left: 0; }
  .lang-menu--content {
    top: -95px; }
    .lang-menu--content::before {
      top: 75px; } }

.lang-menu--btn {
  background-color: transparent;
  border: none;
  text-transform: uppercase; }
  .lang-menu--btn:focus {
    outline: none !important; }

.lang-menu--options {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f7f7f7; }

.lang-menu--option:not(:last-child) {
  margin-bottom: 10px; }

.lang-menu--link {
  line-height: 15px;
  font-size: 15px; }
  .lang-menu--link::before {
    content: '';
    display: inline-block;
    width: 22px;
    height: 15px;
    vertical-align: baseline;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .lang-menu--link:hover {
    text-decoration: none; }
  .lang-menu--link span {
    display: inline-block; }

.page-404 {
  padding-top: 100px; }

.page-404--text {
  margin-bottom: 50px;
  text-align: center;
  font-size: 22px;
  color: #333333; }

.contacts {
  margin-top: 60px;
  padding: 60px 0;
  background-color: #f7f7f7; }

.contacts--title {
  margin-bottom: 50px !important;
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase; }

@media (max-width: 767px) {
  .contacts .contacts--title {
    font-size: 30px; }
  .contacts .point {
    margin-bottom: 25px; } }

.news {
  margin-top: 60px; }
  .news .news--item {
    margin-bottom: 20px; }
  .news .news--title {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase; }
  .news .news--btn {
    width: 170px;
    height: 45px;
    margin: 0 auto;
    padding: 0;
    border: none;
    border-radius: 0;
    line-height: 45px;
    background-color: #4d90bf;
    color: #ffffff !important; }
    .news .news--btn:hover {
      text-decoration: none !important;
      background-color: #609cc6; }
  .news .news--announcement {
    font-size: 14px;
    color: #888888; }

.news--title-link {
  display: block;
  margin-top: 20px;
  color: #333333; }
  .news--title-link h5 {
    font-size: 16px; }
  .news--title-link:hover {
    color: #333333; }

.news--announce {
  color: #888888;
  font-size: 14px; }

.news--date {
  color: #888888;
  font-size: 14px; }

.news--block {
  margin-bottom: 30px;
  padding: 0 15px; }
  .news--block .news-block--title-link {
    color: #333333;
    font-size: 16px; }
  .news--block .news-block--title {
    margin-top: 15px;
    font-size: inherit; }
  .news--block .news-block--announce {
    color: #888888;
    font-size: 14px; }
  .news--block .news-block--date {
    color: #888888;
    font-size: 14px; }

.news--text {
  margin-bottom: 20px; }

.news--date {
  margin-top: 25px;
  font-size: 12px; }

.news--row-btn {
  padding: 0 15px; }
  .news--row-btn .btn {
    padding: 8px 25px; }

.news--row-loader {
  justify-content: center; }

@media (max-width: 768px) {
  .news .news--title {
    font-size: 30px; }
  .news .news--btn {
    width: 100%; }
  .news--row-btn {
    width: 100%;
    justify-content: center; }
  .news--item {
    padding: 0; } }

.cookie-layer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 70px;
  padding-top: 15px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.75); }
  .cookie-layer .col-sm-12.col-md-9,
  .cookie-layer .col-sm-12.col-md-3 {
    margin-bottom: 20px; }
  .cookie-layer .row {
    width: 100%; }
  .cookie-layer .col-sm-12 > .row {
    align-items: center; }

.cookie-layer--text {
  margin: 0; }

.point {
  box-sizing: border-box;
  min-height: 45px;
  border-right: none;
  transition: border-color 0.25s ease-in-out; }
  .point__transparent {
    border: 1px solid #ffffff; }
    .point__transparent .point--fields {
      color: #ffffff;
      background-color: transparent; }
      .point__transparent .point--fields__opened {
        background-color: #ffffff; }
  .point__dark {
    border: 1px solid #d7d7d7; }
    .point__dark .point--fields {
      color: #333333;
      background-color: #ffffff; }
  .point:hover {
    border-color: rgba(255, 255, 255, 0.5);
    transition: border-color 0.25s ease-in-out; }

.point--fields {
  box-sizing: border-box;
  position: relative;
  height: calc(100% - 2px);
  padding: 0 10px;
  line-height: 43px;
  white-space: nowrap;
  overflow-x: hidden; }
  .point--fields__opened {
    overflow-x: visible; }
  .point--fields ul {
    padding: 0;
    list-style: none; }

.point--error {
  position: absolute;
  left: 10px;
  top: 51px;
  color: #ff0000;
  font-size: 12px; }

.point--input {
  width: 100%;
  height: calc(100% - 2px);
  border: none !important;
  padding: 0 !important; }
  .point--input:focus {
    border: none !important;
    padding: 0 !important;
    outline: none !important; }

.point--options {
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 100;
  width: calc(100% + 2px);
  max-height: 195px;
  padding: 0;
  overflow-y: scroll;
  border: 1px solid #ffffff;
  color: #333333;
  background-color: #ffffff;
  list-style: none; }

.point--options::-webkit-scrollbar {
  width: 5px; }

.point--options::-webkit-scrollbar-track {
  background: #f1f1f1; }

.point--options::-webkit-scrollbar-thumb {
  background: #609cc6; }

@media (max-width: 991px) {
  .point:hover {
    border-color: #ffffff; }
  .point--options {
    left: -1px;
    width: calc(100% + 2px); } }

.point--option {
  padding: 10px;
  cursor: pointer;
  white-space: normal;
  line-height: 20px; }
  .point--option:hover {
    background-color: #e7e7e7; }

@media (max-width: 991px) {
  .point__transparent {
    border-right: 1px solid #ffffff; }
  .point--error {
    top: 47px; } }

.tracking-form .col-sm-12.col-md-9.col-xl-10,
.tracking-form .col-sm-12.col-md-3.col-xl-2 {
  padding: 0; }

.tracking-form .col-sm-12 {
  padding: 0; }

.tracking-form__embedded {
  margin-top: -130px; }
  .tracking-form__embedded .tracking-form--form {
    margin-bottom: 60px; }
  .tracking-form__embedded .tracking-form--input {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff; }
    .tracking-form__embedded .tracking-form--input::placeholder {
      color: #ffffff; }
    .tracking-form__embedded .tracking-form--input:hover {
      border-color: rgba(255, 255, 255, 0.5); }
    .tracking-form__embedded .tracking-form--input:focus {
      outline: 2px solid #ffffff; }

.tracking-form h3 {
  font-size: 30px;
  font-weight: 400; }

.tracking-form--form {
  width: 100%; }

.tracking-form--field {
  position: relative; }

.tracking-form--input {
  min-height: 45px;
  margin-bottom: 25px;
  font-size: 16px;
  border-radius: 0; }
  .tracking-form--input:focus {
    box-shadow: none; }
  .tracking-form--input.is-invalid {
    background-image: none; }

.tracking-form--error {
  position: absolute;
  left: 10px;
  top: 45px; }

.tracking-form--btn {
  width: calc(100% - 20px);
  margin-left: 20px;
  min-height: 45px; }

.tracking-form--table {
  margin-bottom: 50px; }
  .tracking-form--table__basic {
    border-bottom: 1px solid #c5cdd6; }
    .tracking-form--table__basic td {
      border-top: none;
      padding-bottom: 40px; }
  .tracking-form--table__bookings td, .tracking-form--table__movements td, .tracking-form--table__airports td {
    padding: 25px 0;
    padding-left: 10.5px; }
  .tracking-form--table__movements thead tr th:first-child {
    padding-left: 70px; }
  .tracking-form--table__movements tr td:first-child {
    position: relative;
    padding-left: 70px; }
    .tracking-form--table__movements tr td:first-child::before {
      content: '';
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 9px;
      height: 9px;
      border: 1px solid #bbbbbb;
      border-radius: 50%; }
    .tracking-form--table__movements tr td:first-child::after {
      content: '';
      position: absolute;
      left: 28.5px;
      top: calc(50% + 13px);
      display: block;
      width: 1px;
      height: calc(100% - 25px);
      background-color: #bbbbbb;
      box-shadow: 0 0 0 9px #ffffff; }
  .tracking-form--table__movements tr:first-child td:first-child::before,
  .tracking-form--table__movements tr:last-child td:first-child::before {
    background-color: #bbbbbb; }
  .tracking-form--table__movements tr:last-child td:first-child::after {
    display: none; }
  .tracking-form--table thead th {
    border: none; }
  .tracking-form--table th {
    color: #888888;
    font-size: 14px;
    border: none; }
  .tracking-form--table td {
    font-size: 14px; }
  .tracking-form--table .tracking-form--mobile-caption {
    display: none; }

@media (max-width: 767px) {
  .tracking-form__embedded {
    margin-top: -185px; }
  .tracking-form--btn {
    width: 100%;
    margin: 0; }
  .tracking-form--table__bookings td, .tracking-form--table__movements td, .tracking-form--table__airports td {
    padding-right: 10.5px; }
  .tracking-form--table__basic {
    border-bottom: none; }
    .tracking-form--table__basic td {
      padding-bottom: 0; }
  .tracking-form--table__movements tr td:first-child {
    padding-left: 10.5px; }
    .tracking-form--table__movements tr td:first-child::before, .tracking-form--table__movements tr td:first-child::after {
      display: none; }
  .tracking-form--table tbody {
    display: block;
    width: 100%; }
  .tracking-form--table thead {
    display: none; }
  .tracking-form--table .tracking-form--mobile-caption {
    display: block;
    text-transform: uppercase;
    font-weight: 500; }
  .tracking-form--table tr {
    display: block;
    border: 2px solid #c5cdd6;
    margin-bottom: 10px; }
  .tracking-form--table td {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: right !important;
    font-size: 16px; } }

@media (max-width: 576px) {
  .tracking-form--table td {
    text-align: left !important; }
  .tracking-form--table .tracking-form--mobile-caption {
    width: 100%; }
  .tracking-form--table .tracking-form--value {
    width: 100%; } }

.underheader {
  position: relative;
  padding-top: 70px;
  padding-bottom: 35px;
  margin-top: -70px;
  margin-bottom: 40px;
  background-color: #4d5254;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .underheader .wrapper {
    z-index: 1; }
  .underheader__form {
    padding-bottom: 120px; }
  .underheader__tracking {
    background-image: url(media/bg1.jpg); }
  .underheader__trips {
    background-image: url(media/bg2.jpg); }
  .underheader__contacts {
    background-image: url(media/bg3.jpg); }
  .underheader__lg {
    padding-bottom: 150px; }
  .underheader__xl {
    padding-bottom: 210px; }
  .underheader__services {
    background-image: url(media/services.jpg); }
  .underheader__planes {
    background-image: url(media/planes.jpg); }
  .underheader__news {
    background-image: url(media/news.jpg); }
  .underheader__order {
    padding-bottom: 35px; }

.underheader--filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.25); }

@media (max-width: 991px) {
  .underheader__lg {
    padding-bottom: 320px; }
  .underheader__xl {
    padding-bottom: 590px; } }

@media (max-width: 767px) {
  .underheader__form {
    padding-bottom: 200px; }
  .underheader__lg {
    padding-bottom: 360px; }
  .underheader__xl {
    padding-bottom: 675px; }
  .underheader h1 {
    overflow: hidden; } }

.ua-input {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: 45px;
  border: 1px solid #ffffff;
  border-right: none;
  color: #ffffff;
  transition: border 0.15s ease-in-out; }
  .ua-input__single {
    border-right: 1px solid #ffffff; }
  .ua-input.active .ua-input--label {
    transform: translateY(-7px);
    transition: all 0.25s ease-in-out;
    font-size: 11px; }
  .ua-input:hover {
    border-color: rgba(255, 255, 255, 0.5);
    transition: border-color 0.15s ease-in-out; }
  .ua-input.alt2 input {
    color: black;
    border: 0px;
    border-bottom: 1px solid #999;
    padding-left: 0; }
    .ua-input.alt2 input:focus {
      border-bottom: 1px solid black; }
    .ua-input.alt2 input::placeholder {
      color: #e1e1e1;
      opacity: 1; }
  .ua-input.alt2 .ua-input--label {
    color: #999;
    left: 0; }
  .ua-input .error-container {
    display: none;
    color: #ba0c2f;
    font-size: 12px;
    margin-top: 2px; }
  .ua-input.error input {
    border-bottom: 1px solid #ba0c2f; }
    .ua-input.error input:focus {
      border-bottom: 1px solid #ba0c2f; }
  .ua-input.error .error-container {
    display: block; }
  .ua-input.success input {
    border-bottom: 1px solid #18b57f; }
    .ua-input.success input:focus {
      border-bottom: 1px solid #18b57f; }

.ua-input--input {
  position: relative;
  z-index: 38;
  width: 100%;
  padding: 15px 10px 5px;
  border: none;
  color: #ffffff;
  background-color: transparent; }
  .ua-input--input:focus {
    outline: none; }

.ua-input--label {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all 0.1s ease-in-out;
  font-size: 16px;
  color: #ffffff; }

.search-form {
  flex-grow: 1; }
  .search-form .col-sm-12.col-md-9.col-xl-10,
  .search-form .col-sm-12.col-md-3.col-xl-2 {
    padding: 0;
    margin-bottom: 25px; }
  .search-form .col-sm-12 {
    padding: 0; }
  .search-form__embedded {
    margin-top: -130px; }
    .search-form__embedded .search-form--form {
      margin-bottom: 60px; }

.search-form--form {
  width: 100%; }

.search-form--btn {
  min-height: 45px;
  min-width: 200px; }

.search-form--result-item {
  margin-bottom: 35px; }
  .search-form--result-item p:first-child {
    margin-bottom: 0;
    font-size: 18px; }

@media (max-width: 767px) {
  .search-form__embedded {
    margin-top: -185px; }
  .search-form--btn {
    margin: 0 auto; } }

.contacts-form .col-xl-12 {
  padding: 0; }

.contacts-form .col-sm-12.col-md-5,
.contacts-form .col-sm-12.col-md-2 {
  padding: 0; }

.contacts-form__embedded {
  margin-top: -130px; }
  .contacts-form__embedded .contacts-form--form {
    margin-bottom: 85px; }

.contacts-form--form {
  margin-bottom: 30px; }

.contacts-form--list-row {
  border: 1px solid #c5cdd6;
  border-left: none;
  border-right: none;
  padding: 30px 0; }
  .contacts-form--list-row__only {
    border: none; }

.contacts-form--list-title {
  padding-bottom: 15px;
  font-size: 24px;
  color: #333333; }

.contacts-form--item-name {
  margin-bottom: 15px;
  font-size: 14px;
  color: #888888;
  text-transform: uppercase; }

.contacts-form--item {
  position: relative;
  margin-bottom: 25px;
  padding: 0 15px;
  padding-left: 22px; }
  .contacts-form--item::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 16px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .contacts-form--item__adress::before {
    background-image: url(media/maps-and-flags.svg); }
  .contacts-form--item__email::before {
    top: 0;
    background-image: url(media/mail.png); }
  .contacts-form--item__phone::before {
    background-image: url(media/telephone.svg); }
  .contacts-form--item__hours p:not(:first-child) {
    margin-bottom: 0; }
  .contacts-form--item__hours::before {
    background-image: url(media/time.svg); }

@media (max-width: 767px) {
  .contacts-form__embedded {
    margin-top: -225px; }
    .contacts-form__embedded .point {
      margin-bottom: 15px; } }

.trips-form .col-sm-12.col-md-12.col-lg-3.col-xl-3 {
  padding: 0;
  margin-bottom: 25px; }

.trips-form .col-sm-12.col-md-4,
.trips-form .col-sm-12.col-md-8 {
  padding: 0; }

.trips-form__embedded {
  margin-top: -176px; }
  .trips-form__embedded.expanded {
    margin-top: -235px; }
  .trips-form__embedded .search-form--form {
    margin-bottom: 60px; }

@media (max-width: 991px) {
  .trips-form .col-btn {
    display: none; } }

@media (max-width: 991px) {
  .trips-form__embedded {
    margin-top: -340px; }
    .trips-form__embedded.expanded {
      margin-top: -620px; }
      .trips-form__embedded.expanded .trips-form--form {
        margin-bottom: 70px; } }

@media (max-width: 767px) {
  .trips-form__embedded {
    margin-top: -385px; }
    .trips-form__embedded.expanded {
      margin-top: -700px; }
      .trips-form__embedded.expanded .trips-form--form {
        margin-bottom: 115px; } }

.trips-form--form {
  width: 100%;
  margin-bottom: 55px; }

.trips-form--more-options {
  padding: 0;
  height: 45px;
  margin-bottom: 20px;
  border: none;
  background: none;
  color: #ffffff; }
  .trips-form--more-options i {
    vertical-align: bottom; }
  .trips-form--more-options > span {
    display: none; }

@media (min-width: 992px) {
  .trips-form--more-options {
    margin-top: -20px; } }

@media (max-width: 991px) {
  .trips-form--fieldset {
    border-right: 1px solid #ffffff; }
  .trips-form--input-wrapper {
    border-right: 1px solid #ffffff; }
  .trips-form--more-options {
    width: 100%;
    min-height: 45px;
    border: 1px solid #ffffff;
    background-color: none; } }

.trips-form--dropdown {
  height: 45px;
  border: 1px solid #ffffff;
  color: #ffffff;
  line-height: 45px;
  transition: border-color 0.25s ease-in-out; }
  .trips-form--dropdown:hover {
    border-color: rgba(255, 255, 255, 0.5);
    transition: border-color 0.25s ease-in-out; }

.trips-form--dropdown-inner {
  position: relative; }

.trips-form--close svg {
  margin-bottom: 2.5px;
  fill: #ffffff; }

.trips-form--close:hover svg {
  opacity: 0.5; }

.trips-form--dropdown-placeholder {
  padding-left: 15px; }

.trips-form--dropdown-filled {
  padding-left: 15px; }

.trips-form--dropdown-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 120;
  max-height: 320px;
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
  color: #333333;
  background-color: #ffffff;
  list-style: none; }
  .trips-form--dropdown-list h5 {
    margin-bottom: 10px;
    font-size: 18px; }
  .trips-form--dropdown-list ul {
    padding: 0;
    list-style: inherit; }
    .trips-form--dropdown-list ul label {
      width: 100%; }
    .trips-form--dropdown-list ul .control-label {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-grow: 1;
      font-size: 13px; }
  .trips-form--dropdown-list label {
    color: inherit !important; }

.trips-form--dropdown-name {
  flex-grow: 1;
  padding-right: 7px; }

.trips-form--dropdown-list::-webkit-scrollbar {
  width: 5px; }

.trips-form--dropdown-list::-webkit-scrollbar-track {
  background: #f1f1f1; }

.trips-form--dropdown-list::-webkit-scrollbar-thumb {
  background: #609cc6; }

.trips-form--mobile-caption {
  display: none; }

.trips-form--value button {
  padding: 10px;
  min-width: 120px; }

@media (max-width: 991px) {
  .trips-form--dropdown-list {
    left: -1px;
    width: calc(100% + 2px); }
  .trips-form--result thead {
    display: none; }
  .trips-form--result .trips-form--mobile-caption {
    display: block;
    text-transform: uppercase;
    font-weight: 500; }
  .trips-form--result tr {
    display: block;
    border: 2px solid #c5cdd6;
    margin-bottom: 10px; }
  .trips-form--result td {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: right !important;
    font-size: 16px; } }

@media (max-width: 576px) {
  .trips-form--result td {
    text-align: left !important; }
  .trips-form--result .trips-form--mobile-caption {
    width: 100%; }
  .trips-form--result .trips-form--value {
    width: 100%; } }

.trips-form--steps {
  position: relative; }

.trips-form--steps-inner {
  position: relative;
  top: -20px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #bbbbbb;
  border-top: none;
  background-color: #ffffff; }

.trips-form--steps-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px 30px; }

.trips-form--steps-route {
  display: flex;
  align-items: center; }

.trips-form--steps-figures {
  position: relative; }
  .trips-form--steps-figures .trips-form--steps-line {
    width: 50px;
    height: 1px;
    background-color: #bbbbbb; }
  .trips-form--steps-figures .trips-form--steps-circle {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    border: 1px solid #bbbbbb;
    border-radius: 50%;
    background-color: #ffffff; }
    .trips-form--steps-figures .trips-form--steps-circle__left {
      left: 0; }
    .trips-form--steps-figures .trips-form--steps-circle__right {
      right: 0; }

.trips-form--steps-flightNumber {
  margin-right: 30px;
  min-width: 65px; }

.trips-form--steps-equipment {
  position: relative;
  margin-left: auto;
  padding-left: 35px; }
  .trips-form--steps-equipment::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(media/plane.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }

.trips-form--steps-btn {
  padding: 5px; }

.trips-form--steps-route .trips-form--steps-value p {
  margin-bottom: 0; }
  .trips-form--steps-route .trips-form--steps-value p:first-child {
    margin-bottom: 5px; }
  .trips-form--steps-route .trips-form--steps-value p:last-child {
    font-size: 14px; }

.trips-form--steps-route .trips-form--steps-value:first-child {
  margin-right: 10px; }

.trips-form--steps-route .trips-form--steps-value:last-child {
  margin-left: 10px; }

@media (max-width: 767px) {
  .trips-form--steps-item {
    flex-direction: column;
    align-items: center; }
  .trips-form--steps-item {
    padding: 20px 30px; }
  .trips-form--steps-flightNumber {
    margin-bottom: 15px;
    font-size: 18px; }
  .trips-form--steps-route {
    margin-bottom: 15px; }
    .trips-form--steps-route .trips-form--steps-value:first-child {
      margin-right: 10px; }
    .trips-form--steps-route .trips-form--steps-value:last-child {
      margin-left: 10px; }
  .trips-form--steps-equipment {
    margin: auto; } }

@media (max-width: 576px) {
  .trips-form--steps-flightNumber {
    margin: 0;
    margin-bottom: 25px; }
  .trips-form--steps-route {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px; }
    .trips-form--steps-route .trips-form--steps-value:first-child, .trips-form--steps-route .trips-form--steps-value:last-child {
      margin: 0;
      width: 100%;
      text-align: center; }
    .trips-form--steps-route .trips-form--steps-value:first-child {
      margin-bottom: 15px; }
    .trips-form--steps-route .trips-form--steps-figures {
      margin-bottom: 15px; } }

.datepicker {
  box-sizing: border-box;
  height: 45px;
  border-radius: 0;
  background-color: transparent; }
  .datepicker .dropdown.is-mobile-modal {
    height: 100%;
    border-radius: 0; }
  .datepicker .dropdown-trigger {
    height: 100%; }
  .datepicker .control {
    height: 100%; }
  .datepicker input {
    height: 100%;
    border-radius: 0;
    padding: 10px;
    background-color: transparent;
    color: #ffffff;
    transition: border-color 0.25s ease-in-out; }
    .datepicker input:hover {
      border-color: rgba(255, 255, 255, 0.5);
      transition: border-color 0.25s ease-in-out; }
    .datepicker input:focus {
      border-color: #ffffff; }
  .datepicker input + .icon {
    top: 50% !important;
    transform: translateY(-50%); }
  .datepicker .dropdown-item:focus, .datepicker .dropdown-item:hover {
    background-color: #ffffff !important;
    outline: none; }
  .datepicker .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #4d90bf; }
  .datepicker .datepicker-cell {
    font-size: 14px; }

.breadcrumb {
  margin: 0;
  margin-bottom: 20px;
  padding: 0.75rem 0;
  background: none; }

.breadcrumb-item {
  color: #ffffff !important; }
  .breadcrumb-item .breadcrumb--link {
    padding: 0;
    color: #ffffff;
    font-size: 16px; }

.breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff; }

@media (max-width: 767px) {
  .breadcrumb {
    display: none; } }

.page-footer {
  margin-top: 75px;
  flex-shrink: 0; }
  .page-footer .col-sm {
    padding: 0;
    text-align: left; }
  .page-footer .wrapper {
    padding: 50px 15px 20px;
    border-top: 1px solid #ececec; }
  .page-footer .col-sm-12.col-md-6.col-lg:not(:last-child) {
    padding-right: 15px; }

.page-footer--subtitle {
  display: block;
  margin-bottom: 10px;
  color: #444444;
  font-size: 18px; }

a.page-footer--subtitle:hover {
  color: #444444; }

.page-footer--item {
  color: #8d8d8d;
  font-size: 16px; }
  .page-footer--item:hover {
    color: #444444; }
  .page-footer--item a {
    color: #8d8d8d; }

.page-footer--support .page-footer--item {
  color: #8d8d8d; }
  .page-footer--support .page-footer--item:hover {
    color: #8d8d8d; }
  .page-footer--support .page-footer--item a {
    color: #8d8d8d;
    font-size: 16px; }
    .page-footer--support .page-footer--item a:hover {
      color: #444444; }
  .page-footer--support .page-footer--item:not(:last-child) a:hover {
    color: #8d8d8d;
    text-decoration: none;
    cursor: text; }

@media (max-width: 767px) {
  .page-footer {
    padding-bottom: 35px; } }

.page-footer--support p {
  margin: 0; }

.page-footer--support .col-sm-12 {
  padding: 0; }

.page-footer--copyright {
  margin-top: 40px;
  padding: 0 15px;
  text-align: center;
  font-size: 12px; }

@media (max-width: 991px) {
  .page-footer .col-sm-12.col-md-6.col-lg:not(:last-child) {
    margin-bottom: 25px;
    padding-right: 0; } }

@media (max-width: 767px) {
  .page-footer .col-sm-12.col-md-6.col-lg {
    text-align: center; }
    .page-footer .col-sm-12.col-md-6.col-lg:not(:last-child) {
      margin-bottom: 25px; }
  .page-footer--support {
    width: 100%;
    text-align: center; } }

.fleet {
  padding-top: 60px; }
  .fleet .row {
    justify-content: center; }
  .fleet .fleet--title {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase; }
  .fleet .nav-tabs {
    margin-bottom: 30px;
    padding: 0 15px;
    border: none;
    flex-wrap: nowrap;
    justify-content: center; }
  @media (max-width: 991px) {
    .fleet .nav-tabs {
      padding: 0;
      justify-content: flex-start; } }
  .fleet .nav-tabs--outer {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px; }
  .fleet .nav-tabs--wrapper {
    width: 100%;
    max-width: 100%; }
  @media (max-width: 991px) {
    .fleet .nav-tabs--outer {
      height: 37px;
      overflow-y: hidden; }
    .fleet .nav-tabs--wrapper {
      overflow-x: auto;
      overflow-y: hidden;
      height: 45px;
      -webkit-overflow-scrolling: touch; }
    .fleet .nav-tabs {
      padding-bottom: 0; }
    .fleet .nav-tabs::-webkit-scrollbar {
      display: none; } }
  .fleet .tab-content {
    min-height: 250px; }
  .fleet .nav-item {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    min-width: 140px;
    max-width: 260px; }
    .fleet .nav-item button {
      white-space: nowrap;
      background-color: transparent;
      color: #a8a8a8 !important;
      border: none; }
      .fleet .nav-item button:hover {
        border-color: transparent !important; }
    .fleet .nav-item .nav-link.active {
      position: relative;
      border: none;
      color: #317cc2 !important; }
      .fleet .nav-item .nav-link.active::after {
        content: '';
        position: absolute;
        top: 85%;
        left: 0;
        display: block;
        width: 100%;
        height: 5px;
        background-color: #4d90bf; }
  .fleet .fleet--about-btn {
    align-self: flex-end; }

.fleet--plain-block {
  margin-bottom: 80px; }
  .fleet--plain-block img {
    width: 100%; }
  .fleet--plain-block .col-md-12 {
    margin-bottom: 15px; }

.fleet--about {
  display: flex;
  flex-wrap: wrap; }

.fleet--plain-block:last-child {
  margin-bottom: 0; }

.fleet--plain-img {
  padding-top: 20px;
  padding-left: 50px; }

.fleet--announce {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  min-height: 280px; }

.fleet--announce-text {
  max-width: 80%;
  margin-bottom: 15px; }

.fleet--image {
  padding-top: 15px; }

@media (max-width: 767px) {
  .fleet .fleet--title {
    font-size: 30px; }
  .fleet--announce {
    flex-direction: column;
    order: 1; }
  .fleet--image {
    order: 0; }
  .fleet--plain-img {
    padding-left: 0; }
  .fleet--announce-text {
    max-width: none; } }

.fleet--info-section {
  margin-bottom: 25px; }

.fleet--about-btn {
  width: 280px;
  height: 60px;
  min-height: 45px;
  padding: 0;
  border: none;
  border-radius: 0;
  vertical-align: middle;
  align-self: flex-end;
  line-height: 60px;
  background-color: #4d90bf;
  color: #ffffff !important; }
  .fleet--about-btn:hover {
    text-decoration: none !important;
    background-color: #609cc6; }

@media (max-width: 767px) {
  .fleet--plain-img {
    order: 0;
    margin-bottom: 25px; }
  .fleet--about {
    order: 1; }
  .fleet--about-btn {
    width: 100%;
    height: auto;
    min-height: 45px;
    line-height: 45px; } }

.banner {
  position: relative;
  padding: 100px 0 0px;
  margin-top: -70px;
  height: 385px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(media/banner.jpg); }
  .banner form {
    min-height: 200px;
    width: 100%; }
    .banner form .md-list.md-theme-default {
      background-color: rgba(0, 0, 0, 0.7); }
    .banner form label {
      width: 100%;
      text-align: left;
      color: #ffffff; }
  .banner .banner--more-options .more-options {
    color: #ffffff !important; }
    .banner .banner--more-options .more-options:hover {
      text-decoration: none; }
    .banner .banner--more-options .more-options:focus {
      text-decoration: none; }
  .banner .md-icon.md-icon-font.md-icon-image.md-date-icon.md-theme-default {
    display: none;
    width: 0;
    height: 0; }
  .banner .md-field.md-theme-default:after {
    background-color: #ffffff; }
  .banner .md-field.md-theme-default:before {
    background-color: #ffffff; }
  .banner .md-button.md-raised.md-primary.form-input.form-input__submit.md-theme-default {
    width: 100%;
    min-height: 45px;
    background-color: #4d90bf !important; }
  .banner .md-ripple svg {
    path-fill: #ffffff; }

.banner--title {
  display: block;
  width: 100%;
  margin-bottom: 40px !important;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase; }

@media (max-width: 991px) {
  .banner {
    padding: 100px 0 0;
    height: auto;
    min-height: 570px; } }

@media (max-width: 768px) {
  .banner {
    padding: 85px 0 0;
    height: auto; } }

.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px; }

.services--item {
  width: 33%;
  min-width: 320px;
  flex-grow: 1;
  padding-top: 40px;
  padding-bottom: 40px;
  border: none;
  text-align: center;
  font-size: 24px; }
  .services--item::before {
    content: '';
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%; }
  .services--item:nth-child(1)::before {
    background-image: url(media/plane.svg); }
  .services--item:nth-child(2)::before {
    background-image: url(media/maps-and-flags.svg); }
  .services--item:nth-child(3)::before {
    background-image: url(media/telephone.svg); }

.services--item-inner {
  display: inline-block;
  width: 100%;
  text-align: center; }

.services--link {
  color: #333333 !important;
  text-transform: uppercase;
  font-weight: 500; }

.trips-form--result {
  width: 100%; }

.trips-form--result-item {
  width: 100%;
  border: 1px solid #c5cdd6;
  padding: 30px;
  margin-bottom: 20px; }

.trips-form--result-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; }

.trips-form--from,
.trips-form--to {
  display: flex;
  flex-direction: column; }
  .trips-form--from p:last-child,
  .trips-form--to p:last-child {
    margin: 0; }

.trips-form--flight {
  margin-bottom: 10px;
  font-weight: 500; }

.trips-form--from {
  margin-right: 10px; }

.trips-form--to {
  position: absolute;
  left: 300px;
  margin-left: 10px; }

.trips-form--place {
  margin-bottom: 5px;
  font-size: 12px; }

.trips-form--time {
  margin-bottom: 5px;
  font-size: 30px; }

.trips-form--date {
  font-size: 16px; }

.trips-form--relation {
  position: absolute;
  left: 120px;
  top: 46px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  transform: translateY(-55%); }

.trips-form--circle {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: 9px;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  background-color: #ffffff; }
  .trips-form--circle__from {
    left: 0; }
  .trips-form--circle__to {
    right: 0; }

.trips-form--line {
  width: 150px;
  height: 1px;
  background-color: #bbbbbb; }

.trips-form--duration,
.trips-form--steps-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.trips-form--duration {
  top: 26px;
  font-size: 12px; }

.trips-form--steps-btn {
  bottom: 0;
  min-width: 113px;
  padding: 0;
  color: #333333 !important;
  text-decoration: none;
  background: none; }
  .trips-form--steps-btn span {
    padding-bottom: 2px;
    background-image: linear-gradient(to right, #333333 75%, transparent 25%);
    background-position: 0 1.15em;
    background-repeat: repeat-x;
    background-size: 3px 1px; }
  .trips-form--steps-btn:hover {
    text-decoration: none;
    background: none; }
  .trips-form--steps-btn:focus {
    text-decoration: none; }
  .trips-form--steps-btn:active {
    text-decoration: none; }
  .trips-form--steps-btn::first-letter {
    text-transform: uppercase; }
  .trips-form--steps-btn button {
    width: 100%; }
  .trips-form--steps-btn__mobile {
    display: none; }

.trips-form--isavailable {
  padding: 10px;
  align-self: center;
  margin-left: auto;
  text-align: center;
  color: #ffffff;
  background-color: #cdcdcd; }
  .trips-form--isavailable::first-letter {
    text-transform: uppercase; }

.trips-form--isavailable-inner {
  text-align: center;
  background-color: #cdcdcd; }

@media (max-width: 767px) {
  .trips-form--flight {
    font-size: 18px;
    text-align: center; }
  .trips-form--result-item {
    padding: 10px; }
  .trips-form--result-content {
    justify-content: space-around; }
  .trips-form--relation {
    position: relative;
    left: -5px;
    top: auto;
    transform: none;
    height: auto; }
  .trips-form--to {
    position: static; }
  .trips-form--isavailable {
    display: block;
    margin: 0;
    width: 80vw;
    margin-top: 20px; } }

@media (max-width: 576px) {
  .trips-form--from {
    margin-right: auto; }
  .trips-form--to {
    margin-left: auto; }
    .trips-form--to p {
      text-align: right; }
  .trips-form--place {
    max-width: 135px; }
  .trips-form--line {
    width: 80px; }
  .trips-form--result-content {
    position: relative; }
  .trips-form--relation {
    position: absolute;
    top: 43px;
    left: 50%;
    transform: translateX(-50%); }
  .trips-form--duration {
    top: -22px;
    min-width: 100px;
    text-align: center; }
  .trips-form--isavailable {
    width: 100%; }
  .trips-form--steps-btn {
    display: none; }
    .trips-form--steps-btn__mobile {
      position: static;
      display: block;
      width: 100%;
      margin-top: 20px;
      transform: none; }
      .trips-form--steps-btn__mobile span {
        padding-bottom: 2px;
        background-image: linear-gradient(to right, #333333 75%, transparent 25%);
        background-position: 0 1.15em;
        background-repeat: repeat-x;
        background-size: 3px 1px; } }

.links {
  position: absolute;
  left: 50%;
  bottom: 14%;
  transform: translateX(-50%);
  margin-top: 50px;
  display: none; }
  .links .col-sm {
    text-align: center; }
  .links a {
    color: #ffffff !important; }

@media (max-width: 991px) {
  .links {
    bottom: 6%; } }

@media (max-width: 767px) {
  .links {
    bottom: 6%; } }

@media (max-width: 576px) {
  .links {
    bottom: 5%; }
    .links .col-sm {
      margin-bottom: 15px; } }

.text-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.text-images--item {
  width: 200px;
  text-align: center; }

.text-images--caption {
  margin-top: 10px;
  font-size: 12px; }

.freight-charter-order-form {
  width: 750px; }
  .freight-charter-order-form .title {
    font-weight: bold;
    text-align: start; }
  .freight-charter-order-form .row {
    margin-top: 15px; }
    .freight-charter-order-form .row .col {
      padding-left: 0; }
      .freight-charter-order-form .row .col:last-child {
        padding-right: 0; }
    .freight-charter-order-form .row.condition-container {
      color: black;
      flex-wrap: initial; }
      .freight-charter-order-form .row.condition-container .info a {
        font-size: 16px; }
    .freight-charter-order-form .row .btn-submit {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      margin: 0;
      width: auto;
      padding: 0 20px;
      background: #4d90bf; }
  @media (max-width: 1279px) {
    .freight-charter-order-form {
      width: 100%; } }
  @media (max-width: 768px) {
    .freight-charter-order-form .row {
      flex-direction: column; }
      .freight-charter-order-form .row .col {
        padding-right: 0;
        margin-top: 15px; }
        .freight-charter-order-form .row .col:first-child {
          margin-top: 0; }
      .freight-charter-order-form .row.condition-container {
        flex-direction: row; } }

.ua-textarea {
  width: 100%; }
  .ua-textarea textarea {
    width: 100%;
    min-height: 150px; }
  .ua-textarea.alt1 {
    position: relative; }
    .ua-textarea.alt1 .empty-space {
      height: 10px;
      width: 100%; }
    .ua-textarea.alt1 label {
      margin: 0;
      z-index: 0;
      color: #999;
      display: block;
      position: absolute;
      top: 15px; }
    .ua-textarea.alt1 textarea {
      border: 0;
      border-bottom: 1px solid #999;
      z-index: 1;
      position: relative;
      background: transparent; }
      .ua-textarea.alt1 textarea:focus {
        outline: none;
        border-bottom: 1px solid black; }
    .ua-textarea.alt1 .limit-container {
      font-size: 13px; }
    .ua-textarea.alt1.active label {
      transform: translateY(-19px);
      transition: all 0.25s ease-in-out;
      font-size: 11px; }
    .ua-textarea.alt1.success textarea {
      border-bottom: 1px solid #18b57f; }
      .ua-textarea.alt1.success textarea:focus {
        border-bottom: 1px solid #18b57f; }

.ua-datepicker .error-container {
  display: none;
  color: #ba0c2f;
  font-size: 12px;
  margin-top: 2px; }

.ua-datepicker.error .error-container {
  display: block; }

.ua-datepicker.alt2 .datepicker {
  width: 100%; }
  .ua-datepicker.alt2 .datepicker input {
    border: 0;
    border-bottom: 1px solid #999;
    font-size: 16px;
    padding-left: 0;
    color: black;
    cursor: pointer; }
    .ua-datepicker.alt2 .datepicker input::placeholder {
      color: #999; }
  .ua-datepicker.alt2 .datepicker .icon {
    color: #999; }

.ua-datepicker.alt2.success input {
  border-bottom: 1px solid #18b57f; }
